.consent__inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  width: 80%;
  height: calc(85% - 70px);
  padding: 30px 40px 40px 40px;
  margin: 5% auto;
  color: white;
  background-color: var(--romantic-bg);
  background-color: var(--romantic-header-bg);
  border-radius: 24px;
  outline: none;
}

.consent__inner::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

.consent__inner::-webkit-scrollbar-track {
  border-radius: 20px;
}

.consent__inner::-webkit-scrollbar-thumb {
  background-color: #22262a;
  border: 1px solid #676e7571;
  border-radius: 20px;
}



.consent__title-img {
  height: 48px;

  aspect-ratio: 1/1;
}

.consent__title {
  align-items: center;
  margin-bottom: 30px;
  font-family: "SF Pro Rounded";
  font-size: 34px;
  font-weight: 800;
  line-height: 40px;
}

.consent__title-text {
  margin-left: 20px;
}

.consent__text {
  color: #8d959e;
}

.consent__content-out {
  width: 100%;
  height: calc(100% - 220px);
  padding-top: 40px;
  margin-bottom: 70px;
  border-top: 1px solid #2a2f35;
  border-bottom: 1px solid #2a2f35;
}

.consent__subtitle {
  margin-bottom: 20px;
}

.consent__content {
  overflow-y: auto;
  height: 100%;
  padding: 16px 20px 0 20px;
  color: #5c6269;
  font-family: "SF Pro Rounded";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  background-color: #111315;
  border-radius: 10px;
}


.consent__content a {
  color: #9969ff;
}

.consent__list {
  list-style-position: inside;
  list-style-type: circle;
}

.consent__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

.consent__agree {
  width: 210px;
}

.consent-exit {
  margin-top: 24px;
  color: #936b01;
  font-family: "SF Pro Rounded";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  text-decoration: none;
}

.consent__actions .consent__manage.MuiButton-root.MuiButton-contained {
  margin-left: 20px;
  color: #747b84 !important;
  background: #2a2f35;
}

.consent__actions .consent__exit.MuiButton-root.MuiButton-outlined {
  color: #5c6269;
  border: 2px solid #5c6269;
}

.consent__actions .consent__exit.MuiButton-root.MuiButton-outlined:hover {
  color: #8b949e;
  background-color: #323840;
  border: 2px solid #323840;
}

.consent__actions_left .consent__manage-control {
  margin-left: 20px;
}

.consent__manage-control .MuiTypography-root {
  color: #747b84;
  font-family: "SF Pro Rounded";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.consent__manage-control .MuiCheckbox-root {
  color: #747b84;
}

.consent__manage-control .MuiCheckbox-root.Mui-checked {
  color: #ffd054;
}

@media screen and (max-width: 750px) {
  .consent__title {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 30px;
  }
  .consent__content {
    overflow-y: scroll;
    padding: 24px 0;
  }
  .consent__inner {
    width: calc(100% - 44px);
    height: calc(100% - 44px);
    padding: 15px 20px 20px 20px;
    margin: 22px;
  }
  .consent__actions {
    flex-direction: column-reverse;
    align-items: center;
  }
  .consent__actions_left {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }
  .consent__actions .consent__manage.MuiButton-root.MuiButton-contained {
    margin-left: 0;
  }
  .inbutton.MuiButton-root {
    width: 100% !important;
    margin-top: 6px;
  }


  .consent__content-out {
    width: calc(100% - 40px);
    height: 60%;
    height: calc(100% - 265px);
    padding-top: 0;
    margin-bottom: 0;
  }
  .consent__actions {
    margin-top: 30px;
  }
  .consent__agree {
    width: 100%;
  }
}
