@import "/src/styles/theme.scss";

.container {
  @include phone {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 0;
  }
  position: fixed;
  top: 82px;
  left: calc(50% - 560px);
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 215px;
  min-width: 215px;
  height: calc(100vh - 110px);
}

.list {
  @include phone {
    flex-direction: row;
    margin-bottom: 0;

    gap: 8px;
  }
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.list-title {
  display: flex;
  padding: 0 2px;
  margin-bottom: 4px;
  color: rgba(255, 255, 255, .70);
  font-size: 15px;
  text-transform: uppercase;
}

.item {
  @include phone {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .05);
  }
  display: flex;
  align-items: center;
  padding: 12px;
  color: inherit;
  text-align: left;
  text-decoration: none;
  background-color: transparent;
  border-radius: 12px;
  outline: none;
  transition: background-color .3s ease-in-out;

  gap: 6px;

  &:hover,
  &:focus,
  &:active {
    .item-icon,
    .item-label {
      opacity: 1;
    }
  }


  &.active {
    background-color: rgba(255, 255, 255, .05);

    .item-icon,
    .item-label {
      opacity: 1;
    }
  }
}

.item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  transition: opacity .3s ease-in-out;
  opacity: .7;
}

.item-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding-right: 8px;
  color: white;
  font-size: 17px;
  font-weight: 600;
  transition: opacity .3s ease-in-out;
  opacity: .7;
}

.banners {
  display: flex;
  flex-direction: column;

  gap: 30px;
  & > :first-child {
    margin-top: 4px;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding-bottom: 32px;
  margin-top: 18px;
}

.dropdown {
  position: relative;
  &-content {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 0;
    background: #23262a;
    border-radius: 16px;

    .list-title {
      padding: 16px 16px 0 16px;
    }
    .item {
      @include phone {
        box-shadow: none;
      }
      padding: 16px;
      padding-right: 50px;
    }
  }
}
