@import "/src/styles/theme.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 460px;
  padding-bottom: 24px;
  padding-bottom: 56px;
  background: url("/assets/img/paywall.png");
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-size: cover;

  gap: 16px;
}

.title {
  padding-top: 42px;
  color: rgba(255, 255, 255, .90);
  font-size: 28px;
  font-weight: 800;
  text-align: center;
}
.subtitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 240px;
  margin-bottom: 42px;
  color: rgba(255, 255, 255, .90);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;

  gap: 6px;
}

.main {
  @include phone {
    margin-top: 145px;
  }
  display: flex;
  flex-direction: column;
  margin-top: 195px;

  gap: 32px;
}
.closeButton {
  top: 24px;
  right: inherit;
  left: 24px;
  ;

  svg {
    stroke: white;
  }
}

.icon {
  svg {
    width: 23px;
    height: 23px;

    fill: #9969ff;
  }
}

.paywallCard {
  display: flex;
  flex-direction: column;
  padding: 12px;
  // margin-bottom: 60px;
  background: rgba(255, 255, 255, .10);
  border: 2px solid rgba(255, 255, 255, .10);
  border-radius: 16px;

  backdrop-filter: blur(50px);
  gap: 24px;
}

.paywallCardItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
  padding-right: 12px;
}

.paywallCardIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: 44px;
  height: 44px;
  padding: 6px;
}

.paywallCardLabel {
  font-size: 16px;
  font-weight: 800;
}



.errorContainer {
  @include phone {
    min-width: 100%;
    min-height: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 460px;
  height: 622px;
  padding-bottom: 24px;

  gap: 16px;
}

.errorIcon {
  width: 180px;
  height: 180px;
}

.errorTitle {
  @include desktop {
    width: 300px;
  }
  margin-bottom: 38px;
  color: rgba(255, 255, 255, .90);
  font-size: 28px;
  font-weight: 800;
  text-align: center;
}
.errorDescription {
  margin-bottom: 68px;
  color: rgba(255, 255, 255, .80);
  font-size: 16px;
}
.errorButton {
  @include desktop {
    margin-bottom: 50px;
  }
  width: 100%;
  padding: 10px 24px;
  color: #0d0e10 !important;
  font-size: 20px;
  font-weight: 800;
  background-color: #9969ff;
  border-radius: 14px;
}
.subscribleButton {
  width: 100%;
  padding: 12px;
  color: #0d0e10 !important;
  font-size: 20px;
  font-weight: 800;
  background-color: #9969ff;
  border-radius: 14px;
}
