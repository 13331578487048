@import "/src/styles/theme.scss";

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .70);

  backdrop-filter: blur(2px);
}
