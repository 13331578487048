@import "/src/styles//theme.scss";

.container {
  @include phone {
    gap: 5px;
  }
  display: flex;
  align-items: center;
  color: var(--main-color);
  text-decoration: none;
  cursor: pointer;

  gap: 6px;
}



.label {
  margin-top: 2px;
  color: white;
  font-family: "SF Pro Rounded", "Inter", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 28px;
  font-weight: 900;
  line-height: 24px;

  @media screen and (max-width: 1100px) {
    font-size: 16px;
  }
}

.colorPrimary {
  color: var(--romantic-primary-color);
}
