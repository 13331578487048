@import "/src/styles//theme.scss";
.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.title {
  @include phone {
    font-size: 34px;
    line-height: 40.57px;
  }
  display: flex;
  margin-bottom: 16px;
  font-family: SF Pro Rounded;
  font-size: 50px;
  font-weight: 800;
  line-height: 59.67px;
  text-align: center;
}

.description {
  @include phone {
    font-size: 17px;
    line-height: 20.29px;
  }
  display: flex;
  margin-bottom: 32px;
  font-family: SF Pro Rounded;
  font-size: 30px;
  font-weight: 400;
  line-height: 35.8px;
  text-align: center;
}

.button {
  display: flex;
  width: 230px;
  height: 56px;
  padding: 16px 28px;
  background: rgba(255, 186, 44, 1);
  border-radius: 16px;

  gap: 0;
}

.poster {
  @include phone {
    top: 200px;
    min-width: 600px;
  }
  position: absolute;
  top: 100px;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  pointer-events: none;
  user-select: none;

  .poster-container {
    width: 100%;
    max-width: 1369.89px;
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 25%;
      margin: auto;
      background: linear-gradient(transparent, #111315 25%);
    }
  }

  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
}
