@import "/src/styles//theme.scss";
.container {
  @include phone {
    padding: 0;

    gap: 12px;
  }
  display: flex;
  align-items: center;
  padding: 6px 12px 6px 8px;
  border-radius: 12px;
  cursor: pointer;

  gap: 8px;
}

.avatar {
  overflow: hidden;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: #191c20;
  background: #191c20;
  border-radius: 8px;
}
.avatarIcon {
  padding: 6px;
}

.avatarImage {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.displayName {
  @include phone {
    display: none;
  }
  overflow: hidden;
  max-width: 200px;
  color: #fff;
  font-family: "SF Pro Rounded";
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: .03em;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  @include phone {
    padding: 7px 11px;
    font-size: 13px;
    border-radius: 8px;
  }
  padding: 9px 16px;
}
