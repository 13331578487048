@import "/src/styles//theme.scss";

.container {
  @include phone {
    padding: 8px 16px;
  }
  display: flex;
  width: 100%;
  max-width: 1160px;
  min-height: 60px;
  padding: 20px;
  background-color: var(--romantic-header-bg);

  gap: 12px;
}

.logotype {
  display: flex;
  align-items: center;
  flex: 1;
}

.navigation {
  display: flex;

  gap: 12px;
}
@media screen and (max-width: 750px) {
  .main__header .header__wrapper {
    margin-top: 0;
  }
}

@media screen and (max-width: 350px) {
  .main__header {
    width: calc(100% - 2*4px);
    padding: 0 4px;
  }
}
