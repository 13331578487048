@import "/src/styles/theme.scss";
.wallet {
  padding: 8px;
  padding-right: 14px;
  font-size: 17px;
  border-radius: 8px;
  svg {
    width: 24px;
    height: 24px;
  }
}
