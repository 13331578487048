@font-face {
  font-family: "SF Pro Rounded";
  font-style: normal;
  font-weight: 400;

  font-display: swap;
  src: url(./SF-Pro-Rounded-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "SF Pro Rounded";
  font-style: normal;
  font-weight: 500;

  font-display: swap;
  src: url(./SF-Pro-Rounded-Medium.woff2) format("woff2");
}

@font-face {
  font-family: "SF Pro Rounded";
  font-style: normal;
  font-weight: 700;

  font-display: swap;
  src: url(./SF-Pro-Rounded-Semibold.woff2) format("woff2");
}

@font-face {
  font-family: "SF Pro Rounded";
  font-style: normal;
  font-weight: 800;

  font-display: swap;
  src: url(./SF-Pro-Rounded-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "SF Pro Rounded";
  font-style: normal;
  font-weight: 900;

  font-display: swap;
  src: url(./SF-Pro-Rounded-Heavy.woff2) format("woff2");
}
