@import "/src/styles/theme.scss";

.container {
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
  background: rgba(26, 28, 32, .9);
  border-top: 1px solid rgba(255, 255, 255, .05);

  backdrop-filter: blur(20px);
}

.list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
  padding: 0 32px;
  padding-top: 5px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  text-align: center;
  text-decoration: none;
  outline: none;
  transition: opacity .3s ease-in-out;
  opacity: .6;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
}

.item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  min-width: 32px;
  height: 32px;

  // svg {
  //   width: 32px;
  //   height: 32px;
  // }
}

.item-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding-right: 8px;
  color: white;
  font-size: 17px;
  font-weight: 600;
  transition: all .3s ease-in-out;
}

// .character-create {
//   opacity: 1;
//   .item-label {
//     color: #9969ff;
//   }
// }
