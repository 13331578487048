@import url(./assets/fonts/sf-pro-rounded.css);

* {
  box-sizing: border-box;
  /* outline: 1px solid red; */
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #22262a;
    border: 1px solid #676e7571;
    border-radius: 20px;
  }
}


:root {
  --romantic-header-bg: #111315;
  --romantic-bg: #111315;
  --romantic-primary-color: #9969FF;

}

body {
  color: #fff;
  background-color: var(--romantic-bg);
  min-height: 100%;
  overflow: hidden;
}

#root {
  min-height: 100%;
}


#modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.inbutton.MuiButton-contained {
  color: black !important;
  background: linear-gradient(180deg, #FFD054 0%, #F6A403 100%);
}

.inbutton.MuiButton-contained:hover {
  filter: brightness(1.2);
  transition: 0.6s;
}

.inbutton.MuiButton-contained:disabled {
  opacity: 0.6;
  color: black;
}

.App .inbutton.MuiButton-outlined {
  color: var(--romantic-primary-color);
  background-color: inherit;
  border-color: var(--romantic-primary-color);
}

.App .inbutton.MuiButton-outlined:hover {
  background-color: var(--romantic-primary-color);
  color: black;
  filter: brightness(1.2);
  opacity: 1;
  border-width: 2px;
  border-color: var(--romantic-primary-color);
  transition: 0.6s;
}

.inbutton.MuiButton-outlined.inbutton_inverted {
  color: white;
  background-color: transparent;
  border-color: white;
}

.inbutton.MuiButton-outlined:hover.inbutton_inverted {
  background-color: white;
  color: var(--main-color);
  border-color: white;
}

.MuiCircularProgress-svg {
  color: var(--romantic-primary-color);
}

.App .inbutton {
  font-family: 'SF Pro Rounded', 'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  font-weight: 800 !important;
}

.App .dot-flashing {
  background-color: var(--romantic-primary-color);
  color: var(--romantic-primary-color);
  animation: dotFlashing-yellow 1s infinite linear alternate;
  animation-delay: .5s;
}

.App .dot-flashing::before {
  background-color: var(--romantic-primary-color);
  color: var(--romantic-primary-color);
  animation: dotFlashing-yellow 1s infinite alternate;
  animation-delay: 0s;
}

.App .dot-flashing::after {
  background-color: var(--romantic-primary-color);
  color: var(--romantic-primary-color);
  animation: dotFlashing-yellow 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing-yellow {
  0% {
    background-color: var(--romantic-primary-color);
  }

  50%,
  100% {
    background-color: hsl(44, 100%, 81%);
  }
}

h3 {
  display: flex;
  color: #fff;
  font-family: "SF Pro Rounded";
  font-size: 34px;
  font-weight: 800;
  line-height: 41px;
}

a {
  outline: none;
  text-decoration: none;
  color: inherit;
}

