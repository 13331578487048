@import "/src/styles/theme.scss";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100vh;
}
.scrollbar {
  @include phone {
    max-height: calc(100vh - 132px);
    padding-bottom: 50px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  max-height: calc(100vh - 65px);
}
.content {
  @include phone {
    padding: 0 16px;

    gap: 16px;
  }
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1160px;
}
