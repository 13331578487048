@import "/src/styles/theme.scss";




.subtitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  margin-bottom: 42px;
  color: rgba(255, 255, 255, .70);
  font-size: 17px;
  text-align: center;

  gap: 6px;
}

.main {
  @include phone {
    margin-top: 145px;
  }
  display: flex;
  flex-direction: column;
  margin-top: 195px;

  gap: 32px;
}
.closeButton {
  top: 24px;
  right: inherit;
  left: 24px;
  ;

  svg {
    stroke: white;
  }
}


.errorContainer {
  @include phone {
    min-width: 100%;
    min-height: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 460px;
  height: 622px;
  padding-bottom: 24px;

  gap: 16px;
}

.errorIcon {
  width: 180px;
  height: 180px;
}

.errorTitle {
  @include desktop {
    width: 300px;
  }
  margin-bottom: 35px;
  color: rgba(255, 255, 255, .90);
  font-size: 28px;
  font-weight: 800;
  text-align: center;
}

.errorButton {
  @include desktop {
    margin-bottom: 50px;
  }
  width: 100%;
  padding: 10px 24px;
  font-size: 20px;
  font-weight: 800;
  border-radius: 14px;
}
