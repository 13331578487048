.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding: 24px 10px;
  border-radius: 16px;
  cursor: pointer;
}

.button_empty {
  color: var(--main-color);
  background-color: inherit;
  border: 2px solid var(--main-color);
}

.button_filled {
  color: white;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
}

.button:hover {
  transition-duration: .5s;
  opacity: .6;
}

.inbutton.MuiButton-root {
  height: 48px;
  font-family: "Roboto";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-transform: none;
  box-shadow: none;
  border-radius: 12px;
}

.inbutton.MuiButton-contained {
  color: white;
  background-color: var(--main-color);
  border-color: var(--secondary-color);
}

.inbutton.MuiButton-contained:hover {
  background-color: #4226ad;
  transition: .6s;
}

.inbutton.MuiButton-contained.Mui-disabled {
  color: white;
  background-color: #4226ad;
  opacity: .4;
}

.inbutton.MuiButton-outlined:hover {
  color: white;
  background-color: var(--main-color);
  border-color: var(--main-color);
  border-width: 2px;
  transition: .6s;
  opacity: .6;
}

.inbutton.MuiButton-outlined {
  color: var(--main-color);
  background-color: inherit;
  border-color: var(--main-color);
  border-width: 2px;
}

.inbutton.MuiButton-outlined.inbutton_inverted {
  color: white;
  background-color: transparent;
  border-color: white;
}

.inbutton.MuiButton-outlined:hover.inbutton_inverted {
  color: var(--main-color);
  background-color: white;
  border-color: white;
}

.inbutton.MuiButton-outlined.light {
  border-width: 1px;
}

.inbutton.MuiButton-outlined.light:hover {
  border-width: 1px;
}

@media screen and (max-width: 1390px) {
  .inbutton.MuiButton-root {
    padding: 6px 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 600px) {
  .inbutton.MuiButton-root {
    font-size: 18px;
    line-height: 24px;
  }
}
