@import "/src/styles/mixins/index.scss";

.container {
  @include phone {
    justify-content: start;
    padding: 22px;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.eclipse {
  z-index: -1;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "SF Pro Rounded";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 33px;
  text-align: center;
}

.content {
  @include phone {
    width: 100%;
    padding: 22px 24px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 58px 64px;
  margin: auto;
  color: #fff;
  font-family: "SF Pro Rounded";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background-color: #111315;
  border-radius: 24px;

  gap: 32px;
}

.close {
  position: absolute;
}
.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  gap: 18px;
}
.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 50%;
  cursor: pointer;
  &:hover {
    svg {
      opacity: .7;
    }
  }
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
}
